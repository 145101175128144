import { memo } from 'react'
import { useInView } from 'react-intersection-observer'
import styled from 'styled-components'
import { motion, AnimatePresence } from 'framer-motion'
import { getRelative, getP150, getP40, mediaQuery } from '@/styles'
import { actualVariants, secondVariants } from './AboutWho'

const SectionStyled = styled(motion.section)<{ $order: number | null }>`
  margin-top: ${getRelative(50, 'mobile')};
  padding: ${getRelative(30, 'mobile')} ${getRelative(30, 'mobile')} ${getRelative(20, 'mobile')} ${getRelative(30, 'mobile')};
  order: ${({ $order }) => $order ?? 'auto'};

  .title {
    span {
      display: inline-block;
    }

    ${getP150()}
    font-family: ${({ theme }) => theme.fonts.eklips};
    color: ${({ theme }) => theme.colors.magenta};
    padding-bottom: ${getRelative(30, 'mobile')};
    text-align: left;

    ${mediaQuery.greaterThan('tablet')`
      padding-bottom: ${getRelative(30, 'desktop')};
    `}
  }

  .wrapper {
    display: grid;
    ${getP40()}
    padding: ${getRelative(30, 'mobile')} 0 ${getRelative(40, 'mobile')};
    border-top: 1px solid ${({ theme }) => theme.colors.black};
    grid-template-columns: unset;
    grid-template-rows: unset;
    row-gap: unset;

    ${mediaQuery.greaterThan('tablet')`
      grid-template-columns: ${getRelative(750, 'desktop')} auto;
      grid-template-rows: 2;
      padding: ${getRelative(30, 'desktop')} 0 ${getRelative(120, 'desktop')};
      row-gap: ${getRelative(50, 'desktop')};
    `}

    .subtitle {
      grid-row: unset;
      grid-column: unset;

      ${mediaQuery.greaterThan('tablet')`
        grid-row: 1;
        grid-column: 1 / span 2;
      `}
    }

    .what {
      border-right: unset;
      grid-column:unset;
      grid-row: unset;
      padding-bottom:${getRelative(40, 'mobile')} ;
      padding-right: unset ;
      padding-top: ${getRelative(30, 'mobile')};

      ${mediaQuery.greaterThan('tablet')`
        border-right: 1px solid ${({ theme }) => theme.colors.magenta};
        grid-column: 1 / span 1;
        grid-row: 2;
        padding-bottom: unset;
        padding-right:${getRelative(40, 'desktop')} ;
        padding-top: unset;
      `}
    }
    .projects {
      border-top: 1px solid ${({ theme }) => theme.colors.magenta};
      grid-row: unset;
      grid-column: unset;
      padding-left: unset;
      padding-top: ${getRelative(30, 'mobile')};

      ${mediaQuery.greaterThan('tablet')`
        grid-row: 2;
        grid-column: 2 / span 2;
        padding-left:${getRelative(40, 'desktop')} ;
        border-top: unset;
        padding-top: unset;
      `}

      h3 {
        color: ${({ theme }) => theme.colors.magenta};
        font-weight: 700;
        text-align: left;
      }
    }
  }
`

interface IAboutWhat {
  data: any
}

export const AboutWhat = memo<IAboutWhat>(({ data }) => {
  const [ref, inView] = useInView({})
  const { title_1, title_2, subtitle, intro, intro_bold, intro_2, project, project_2, project_3 } = data
  return (
    <SectionStyled ref={ref} $order={3} id='quefem' layoutScroll>
      <div className='title'>
        <AnimatePresence mode='wait'>
          <motion.span animate={inView ? 'animate' : 'exit'} exit='exit' initial='enter' variants={actualVariants} key={3}>{title_1}</motion.span>
          <motion.span animate={inView ? 'animate' : 'exit'} exit='exit' initial='enter' variants={secondVariants} key={4}> {title_2}</motion.span>
        </AnimatePresence>
      </div>
      <div className='wrapper'>
        <span className='subtitle'>{subtitle}</span>
        <div className='what'>
          <span>{intro}</span>
          <span> {intro_bold}</span>
          <span> {intro_2}</span>
        </div>
        <div className='projects'>
          <h3>{project}</h3>
          <h3>{project_2}</h3>
          <h3>{project_3}</h3>
        </div>
      </div> 
    </SectionStyled>
  )
})
