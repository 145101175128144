import { memo } from 'react'
import styled from 'styled-components'
import { useInView } from 'react-intersection-observer'
import { getRelative, getP120, getP40, mediaQuery, getP15_1 } from '@/styles'
import { motion, AnimatePresence } from 'framer-motion'
import { Lottie } from 'components/lottie'

const _SectionStyled = styled(motion.section)`
    margin: ${getRelative(40, 'mobile')} 0;

    ${mediaQuery.greaterThan('tablet')`
       margin: ${getRelative(40, 'desktop')} 0;
    `}

  .title {
    color: ${({ theme }) => theme.colors.black};
    ${getP40()}
    font-family: ${({ theme }) => theme.fonts.roboto};
  }

  .intro {
    ${getP120()}
    font-family: ${({ theme }) => theme.fonts.eklips};
    color: ${({ theme }) => theme.colors.magenta};
    padding-top: ${getRelative(30, 'mobile')};

    ${mediaQuery.greaterThan('tablet')`
      padding-top: ${getRelative(30, 'desktop')};
    `}

    span {
      text-align: center;
      display: block;
    }
  }
  div:not(#histories) {
    text-align:center;
    padding-top: ${getRelative(30, 'mobile')};
    ${mediaQuery.greaterThan('tablet')`
    padding-top: ${getRelative(30, 'desktop')};

    `}
    .mail_to {
      ${getP15_1()}
        font-family: ${({ theme }) => theme.fonts.roboto_bold};
        font-weight: 500;
        color: ${({ theme }) => theme.colors.black};
        border: 1px solid ${({ theme }) => theme.colors.black};
        border-radius: ${getRelative(5, 'mobile')};
        width: ${getRelative(180, 'mobile')};
        height: ${getRelative(30, 'mobile')};

        ${mediaQuery.greaterThan('tablet')`
          border-radius: ${getRelative(5, 'desktop')};
          width: ${getRelative(200, 'desktop')};
          height: ${getRelative(30, 'desktop')};
        `}
    }

  }
  .lottie-container {
    width: 98vw;
    height: auto;
    ${mediaQuery.greaterThan('tablet')`
      width: 90vw;
    `}
  }
  #hsitories {
    padding:0;
  }

`

interface IHistories {
  reference: any
}

export const Histories = memo<IHistories>(({reference }) => {
  const [ref, inView] = useInView({})
  return (
    <>
      <AnimatePresence mode='wait'>
        <div ref={reference} id='histories'>
          <_SectionStyled ref={ref} animate={inView ? 'animate' : 'exit'} exit='exit' initial='enter' variants={{}} key={10}>
            <div id={'histories'}/>
            <h2 className='title'>Històries</h2>
            <Lottie isActive={inView ? true: false} name={'SomMonmedia'} speed={1}></Lottie>
            <div>
              <button className='mail_to'><a href={'mailto:hola@monmedia.tv'}>hola@monmedia.tv</a> </button>
            </div>
          </_SectionStyled>
        </div>
      </AnimatePresence>
    </>
  )
})
