import { memo } from 'react'
import styled from 'styled-components'
import { useInView } from 'react-intersection-observer'
import { getRelative,mediaQuery, getP150, getP40, getTransition } from '@/styles'
import { motion, AnimatePresence } from 'framer-motion'


const SectionStyled = styled(motion.section)<{$order}>`
  margin-top: ${getRelative(50, 'mobile')};
  padding: ${getRelative(30, 'mobile')} ${getRelative(30, 'mobile')} ${getRelative(20, 'mobile')} ${getRelative(30, 'mobile')};
  order: ${({ $order }) => $order ?? 'auto'};

  .title {
    span {
      display: inline-block;
    }
    ${getP150()}
    font-family: ${({ theme }) => theme.fonts.eklips};
    color: ${({ theme }) => theme.colors.magenta};
    text-align: left;
    padding-bottom: ${getRelative(30, 'mobile')};
    ${mediaQuery.greaterThan('tablet')`
      padding-bottom: ${getRelative(30, 'desktop')};
  `}
  }

  .wrapper {
    display: grid;
    ${getP40()}
    grid-template-columns: 1;
    grid-template-rows:2;
    border-top: 1px solid ${({ theme }) => theme.colors.black};
    border-bottom: 1px solid ${({ theme }) => theme.colors.black};
    padding: ${getRelative(30, 'mobile')} 0 ${getRelative(40, 'mobile')};
    ${mediaQuery.greaterThan('tablet')`
      grid-template-columns: ${getRelative(500, 'desktop')} auto;
      padding: ${getRelative(30, 'desktop')} 0 ${getRelative(120, 'desktop')};
      `}
    .subtitle {
      grid-row:1 ;
      grid-column: 1;
    }
    .who {
      grid-row:2 ;
      grid-column: 1;
      padding-top: ${getRelative(30, 'mobile')};

      ${mediaQuery.greaterThan('tablet')`
        padding-top: 0;
        grid-column: 2 / span 3;
        grid-row:unset;

      `}
     span:first-child {
      display: block;
      padding-bottom: ${getRelative(30, 'desktop')};
     }
     span:nth-child(2) {
      font-weight: bold;
     }
    }
  }
`

interface IAboutWho {
  data: any
}

export const actualVariants = {
  enter: {
    y: '0px',
    transition: { ...getTransition(1), delay: .01}
  },
  animate: {
    y: '-70%',
    transition: { ...getTransition(1),
      delay: .02 }
  },
  exit: {
    y: '0px',
    transition: { ...getTransition() }
  }

}

export const secondVariants = {
  enter: {
    x: '0px',
    transition: { ...getTransition(1), delay: .06}
  },
  animate: {
    x: '-15%',
    transition: { ...getTransition(1.5),
      delay: .06 }
  },
  exit: {
    x: '0px',
    transition: { ...getTransition() }
  }
}



export const AboutWho = memo<IAboutWho>(({ data }) => {
  const [ref, inView] = useInView({})
  const { title_1, title_2, subtitle, intro, intro_bold, intro_2 } = data
  return (
    <SectionStyled ref={ref} $order={5} id='quisom'>
      <div className='title'>
        <AnimatePresence  mode='wait'>
          <motion.span animate={inView ? 'animate' : 'exit'} exit='exit' initial='enter' variants={actualVariants} key={1}>{title_1}</motion.span> 
          <motion.span animate={inView ? 'animate' : 'exit'} exit='exit' initial='enter' variants={secondVariants} key={2}> {title_2}</motion.span>
        </AnimatePresence>
      </div>
        
      <div className='wrapper'>
        <span className='subtitle'>{subtitle}</span>
        <div className='who'>
          <span>{intro}</span>
          <span> {intro_bold}</span>
          <span> {intro_2}</span>
        </div>
      </div>
    </SectionStyled>
  )
})
