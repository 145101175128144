/* eslint-disable quotes */
import { memo, useCallback, useMemo, useRef } from 'react'
import type { NextPage } from 'next'
import styled from 'styled-components'
import { HOME_DATA } from '@/api'
import { HeadTag } from '../components/head'
import { BrandSection, Footer, Header, Project, AboutWho, AboutWhat, Histories, Logo } from '../components/home'
import { NavBar } from '../components/nav/NavBar'

interface IHomePage {
  data: {
    seo: {
      title: string,
      description: string
    },
    header: any[],
    sections: any[]
  }
}

// export async function getStaticPaths() {
//   const paths = []
//   paths.push({ params: {} })
//   // paths.push({ params: {}, locale: 'ca' })

//   return { paths, fallback: 'blocking' }
// }

export async function getStaticProps({}) {
  return {
    props: {
      data: HOME_DATA
    }
  }
}

const Main = styled.main`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
`

function generateRandom(min, max, exclude) {
  let random
  while (!random) {
    const x = Math.floor(Math.random() * (max - min + 1)) + min
    if (exclude.indexOf(x) === -1) random = x
  }
  return random
}

const getSections = (data) => {
  const excluded = [1,8,9,3,5]
  let projectOrder = 1
  return data.map((section, idx)=> {
    const { type } = section
    let order

    if (type === 'project') {
      projectOrder ++
      order = projectOrder
    } else {
      order = generateRandom(1,9, excluded)
      excluded.push(order)
    }

    const orderSectionData = {...section, order }
    if (type === 'about_what') return <AboutWhat key={`about-what-${idx}`} data={orderSectionData} />
    else if (type === 'about_who') return <AboutWho key={`about-who-${idx}`} data={orderSectionData} />
    else if (type === 'project_brands') return <BrandSection key={`brand-${idx}`} />
    else return <Project key={`project-${idx}`} data={orderSectionData} />
  })
}

const HomePage: NextPage<IHomePage> = memo(({ data }) => {
  const { seo, header, sections } = data
  const Sections = useMemo(() => getSections(sections), [sections])
  const sectionProjects = useMemo(() => sections.filter(item => item.type === 'project'), [sections])
  const ref = useRef(null)
  const handleClick = useCallback(() => {
    ref.current?.scrollIntoView({ behavior: 'smooth' })
  },[ref])

  return (
    <>
      <HeadTag data={seo} jsonld={{ data: { seo, projects:sectionProjects }, pageType: 'home' }}/>
      <Header data={header} onClick={handleClick}/>
      <NavBar/>
      <Main>
        <Logo/>
        <Histories reference={ref}/>
        {Sections}
      </Main>
      <Footer/>
    </>
  )
})

HomePage.displayName = 'HomePage'
export default HomePage
