/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react'
import lottie from 'lottie-web'
import styled from 'styled-components'
import { useMount } from '@/hooks'

export interface ILottie {
  isActive: boolean,
  name: any,
  speed?: number
}

const Div = styled.div<any>`
  opacity: ${({ $isActive }) => $isActive ? '1' : '0'};
`

const useLottie = (name, loop = false) => {
  const [ref, setRef] = useState(null)
  const [animation, setAnimation] = useState(null)

  const register = (ref) => {
    if (ref) {
      setRef(ref)
    }
  }

  useEffect(() => {
    if (ref) {
      const _animation = lottie.loadAnimation({
        container: ref,
        renderer: 'svg',
        loop,
        autoplay: false,
        path: `/lottie/${name}.json`,
        name
      })

      setAnimation(_animation)
    }

    return () => animation && animation.destroy()
  }, [ref])

  return { register, animation }
}



export const Lottie = ({ isActive , name, speed = 1 }:ILottie) => {
  const isMount = useMount()
  const { animation, register } = useLottie(name)

  useEffect(() => {
    if (animation && isActive) {
      animation.setSpeed(speed)
      animation.play()
    }

    return () => animation && animation.stop()
  }, [isActive, animation])

  return (isMount && <Div className={'lottie-container ' + name} ref={register} $isActive={isActive}/>)
}
