import { useViewportScroll as framerHook } from 'framer-motion'
import { useEffect } from 'react'

export const useViewportScroll = (SCROLL_CENTINELLA, callback) => {
  const { scrollYProgress } = framerHook()

  useEffect(() => {
    const stop = scrollYProgress.onChange(() => {
      if (scrollYProgress.get() > SCROLL_CENTINELLA) {
        callback(false)
      } else {
        callback(true)
      }
    })

    return () => {
      stop()
    }
  },[SCROLL_CENTINELLA, callback])
}