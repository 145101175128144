/* eslint-disable react/jsx-key */
import { FC } from 'react'
import Head   from 'next/head'
import { home } from './jsonld'

// NOTE: <title> and <meta> elements need to be contained as direct children of the <Head> element,
// or wrapped into maximum one level of <React.Fragment>, otherwise
// the metatags won't be correctly picked up on clientside navigation.
// NOTE:- Segun parece tampoco acepta `Fragments` que son hermanos. Solo pilla el primero.

interface IHead {
  readonly data?: {
    seo?: {
      title: string,
      description: string,
    },
    [key: string]: any
  },
  readonly jsonld?: {
    pageType: string,
    data: any,
    [key: string]: any
  },
  canonical?: string
}

// IMPORTANT => HOSTNAME WITHOUT trailing slash '/'
// const hostname = 'https://staging.monmedia.you.digital'
const hostname = 'https://monmedia.tv'
const defaultOgImage = {
  default: `${hostname}/images/og_cover.jpg`,
  content_type: 'image/jpeg',
  alt: 'monmedia',
  size: {
    height: 630,
    width: 1200
  }
}

export const HeadTag:FC<IHead> = ({ data = {}, jsonld = null, canonical }) => {
  const image = defaultOgImage

  const OgBase = [
    <meta property='og:type' key='og:type' content='website' />,
    <meta property='og:site_name' key='og:site_name' content='monmedia' />,
    <meta property='og:url' key='og:url' content={hostname} />,
    <meta property='og:title' key='og:title' content={data.title} />,
    <meta property='og:description' key='og:description' content={data.description} />,
    <meta property='og:locale' key='og:locale' content='ca-ES' />,
  ]

  const OgImages = [
    <meta property='og:image' key='og:image' content={image.default} />,
    <meta property='og:image:alt' key='og:image:alt' content={image.alt} />,
    <meta property='og:image:type' key='og:image:type' content={image.content_type} />,
    <meta property='og:image:secure_url' key='og:image:secure_url' content={image.default} />,
    <meta property='og:image:height' key='og:image:height' content={`${image.size.height}`} />,
    <meta property='og:image:width' key='og:image:width' content={`${image.size.width}`} />,
  ]

  const OgTwitter = [
    <meta name='twitter:card' key='twitter:card' content='summary_large_image' />,
    <meta property='twitter:domain' key='twitter:domain' content='monmedia.tv' />,
    <meta property='twitter:url' key='twitter:url' content={hostname} />,
    <meta name='twitter:title' key='twitter:title' content={data.title} />,
    <meta name='twitter:description' key='twitter:description' content={data.description} />,
    <meta name='twitter:image' key='twitter:image' content={image.default} />
  ]

  const OgVideo = data.video ? [
    <meta property='og:video:type' content={data.video.content_type} />,
    <meta property='og:video:secure_url' content={data.video.default} />,
    <meta property='og:video:height' content={data.video.size.height} />,
    <meta property='og:video:width' content={data.video.size.width} />,
  ] : []

  return (
    <Head>
      <title>{data.title}</title>
      <meta name='description' content={data.description} />
      {OgBase}
      {OgImages}
      {OgTwitter}
      {OgVideo}
      {jsonld && <script type='application/ld+json' dangerouslySetInnerHTML={{ __html: home(jsonld.data) }} />}
      {canonical && <link key='canonical' rel='canonical' href={canonical} />}
    </Head>
  )
}
