import { memo, useMemo } from 'react'
import styled from 'styled-components'
import { mediaQuery, getRelative } from '@/styles'
import { NavList } from '.'


interface IBurger {
  menuOpen: boolean,
  data: any,
  setIsMenuActive: any
}

export const NAVBAR_BURGER_IMAGES = {
  'burger': {
    alt: 'Icon burger',
    src: '/images/svg/i--menu.svg',
    size: { width: 45, height: 30 }
  },
  'close': {
    alt: 'Icon close',
    src: '/images/svg/i--close.svg',
    size: { width: 30, height: 30 }
  }
}

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 85% auto;
  width: 100%;

  ${mediaQuery.greaterThan('tablet')`
		grid-template-columns: ${getRelative(150, 'desktop')} auto;
		width: unset;
  `}
`

const Button = styled.button`
  align-self: flex-start;
  display: flex;
  grid-column: 2;

  .menu {
    height: auto;
    justify-content: center;
    margin: ${getRelative(20, 'mobile')};
    width: ${getRelative(25, 'mobile')};

    ${mediaQuery.greaterThan('tablet')`
			height: auto;
			margin: unset;
			width: ${getRelative(50, 'desktop')};
    `}
  }

  .close {
    height: auto;
    justify-content: center;
    margin: ${getRelative(22, 'mobile')};
    width: ${getRelative(22, 'mobile')};

    ${mediaQuery.greaterThan('tablet')`
			margin: ${getRelative(15, 'desktop')};
			width: ${getRelative(20, 'desktop')};
    `}
  }

`

export const Burger = memo<IBurger>(({ menuOpen = false, data, setIsMenuActive}) => {
  const img = useMemo(() => NAVBAR_BURGER_IMAGES[menuOpen ? 'close' : 'burger'], [menuOpen])
  const handleOnClick = () => setIsMenuActive(prevState => !prevState)

  return (
    <Wrapper>
      <NavList key={'nav-list'} data={data} isActive={menuOpen} setIsMenuActive={setIsMenuActive}/>
      <Button type='button' onClick={handleOnClick}>
        <img src={img.src} alt={img.alt} className={img.src.includes('close') ? 'close' : 'menu'} width={img.size.width} height={img.size.height}></img>
      </Button>
    </Wrapper>
  )
})
