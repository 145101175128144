import { memo } from 'react'
import type { FC } from 'react'
import styled from 'styled-components'
import { motion } from 'framer-motion'
import { mediaQuery, getRelative, getP25, getP25_1 } from '@/styles'

const FooterStyled = styled(motion.footer)`
  margin:${getRelative(10, 'mobile')} ${getRelative(15, 'mobile')};

  ${mediaQuery.greaterThan('tablet')`
    margin:${getRelative(20, 'desktop')} ${getRelative(120, 'desktop')};
  `}

  .wrapper {
    align-items:end;
    border-top: 1px solid ${({ theme }) => theme.colors.black};
    display: grid;
    grid-template-columns:${getRelative(220, 'mobile')} auto;
    grid-template-rows: 2;
    row-gap: ${getRelative(30, 'mobile')};
    padding: ${getRelative(20, 'mobile')}  0;

    ${mediaQuery.greaterThan('tablet')`
      align-items:end;
      grid-template-columns: repeat(4, 1fr);
      padding: ${getRelative(50, 'desktop')}  0;
      row-gap:${getRelative(50, 'desktop')};
    `}
  }

  .contact {
    ${getP25_1()}
    grid-column: 1/ span 2;

    ${mediaQuery.greaterThan('tablet')`
      grid-column: 1/ span 4;
    `}
  }

  .contact_us {
    grid-column: 1/ span 2;
    display:flex;
    grid-row:2;
    justify-content:center;
    ${mediaQuery.greaterThan('tablet')`
       grid-column: 1/ span 3;
       justify-content:unset;

    `}
    .instagram {
      border: 1px solid ${({ theme }) => theme.colors.magenta};
      margin-left: ${getRelative(10, 'mobile')};
      border-radius: ${getRelative(5, 'mobile')};
      width: ${getRelative(40, 'mobile')};
      height: ${getRelative(40, 'mobile')};

      ${mediaQuery.greaterThan('tablet')`
        margin-left: ${getRelative(10, 'desktop')};
        border-radius: ${getRelative(10, 'desktop')};
        width: ${getRelative(47, 'desktop')};
        height: ${getRelative(47, 'desktop')};
      `}
      img {
        width: ${getRelative(20, 'mobile')};
        height: ${getRelative(20, 'mobile')};
        ${mediaQuery.greaterThan('tablet')`
          width: ${getRelative(25, 'desktop')};
            height: ${getRelative(25, 'desktop')};
        `}
      }
    }

    .mail_to {
      ${getP25()}
      font-family: ${({ theme }) => theme.fonts.roboto_bold};
      font-weight: 500;
      color: ${({ theme }) => theme.colors.magenta};
      border: 1px solid ${({ theme }) => theme.colors.magenta};
      border-radius: ${getRelative(5, 'mobile')};
      padding: ${getRelative(6, 'mobile')} ${getRelative(15, 'mobile')};
      width: auto;
      height: ${getRelative(40, 'mobile')};

      ${mediaQuery.greaterThan('tablet')`
        border-radius: ${getRelative(10, 'desktop')};
        padding: ${getRelative(10, 'desktop')} ${getRelative(15, 'desktop')};
        width: auto;
        height: ${getRelative(49, 'desktop')};
      `}
    }
  }
  .logo {
    grid-column: 1/ 3;
    grid-row:3;
    justify-self:center;
    ${mediaQuery.greaterThan('tablet')`
      grid-row:unset;
      justify-self:self-end;
      grid-column: 4/span 4;
    `}

    img {
      width: ${getRelative(200, 'mobile')};
      height:auto;
      ${mediaQuery.greaterThan('tablet')`
        width: ${getRelative(300, 'desktop')};
    `}
    }
  }
`


export const Footer:FC = memo(() => {

  return (
    <FooterStyled id='contacte' layoutScroll>
      <div className='wrapper'>
        <span className='contact'>Contacte</span>
        <div className='contact_us'>
          <button className='mail_to'><a href={'mailto:hola@monmedia.tv'}>hola@monmedia.tv</a></button>
          <button className='instagram'>
            <a href={'https://www.instagram.com/monmedia.tv/?igshid=YmMyMTA2M2Y%3D'} title='Instagram Monmedia.TV' rel='noreferrer'>
              <img src='/images/svg/rrss--ig.svg' alt='Instagram Logo' />
            </a>
          </button>
        </div>
        <div className='logo'>
          <img src='/images/svg/logo--monmedia--footer.svg' alt='logotip monmedia' />
        </div>
      </div>
    </FooterStyled>
  )
})
