import { memo } from 'react'
import styled from 'styled-components'
import { useDeviceType, useMount } from '@/hooks'
import { mediaQuery, getRelative, getP25_1 } from '@/styles'



const Wrapper = styled.section`
  border-top: 1px solid ${({ theme }) => theme.colors.black};
  display: flex;
  ${getP25_1()}
  flex-direction: column;
  margin: ${getRelative(40, 'mobile')} ${getRelative(15, 'mobile')} ${getRelative(10, 'mobile')} ${getRelative(15, 'mobile')};
  order: 9;
  padding: ${getRelative(20, 'mobile')}  0;

  ${mediaQuery.greaterThan('tablet')`
    margin: ${getRelative(120, 'desktop')} ${getRelative(120, 'desktop')} 0  ${getRelative(120, 'desktop')};
    padding: ${getRelative(50, 'desktop')}  0;
  `}

  img {
    display: flex;
    margin: 0 auto;
    padding: ${getRelative(30, 'mobile')} 0 ${getRelative(10, 'mobile')} 0;
    width:90%;

    ${mediaQuery.greaterThan('tablet')`
      margin: 0;
      padding: ${getRelative(50, 'desktop')} ${getRelative(80, 'desktop')} 0 ${getRelative(80, 'desktop')};
      width: 100%;
    `}
  }
`

export const BrandSection = memo(({  }) => {
  const deviceType = useDeviceType()
  const isMount = useMount()

  return (
    <Wrapper>
      <span>Han confiat en nosaltres:</span>
      {isMount && <img src={`/images/img/Logos_${deviceType !== 'mobile' ? 'Desktop' : 'Mobile'}.png`} alt='logos'></img>}
    </Wrapper>

  )
})
