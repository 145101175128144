import { memo } from 'react'
import styled from 'styled-components'
import { getRelative, mediaQuery } from '@/styles'


const Div = styled.div<any>`
  opacity: ${({ isActive }) => isActive ? '1' : '0'};

  img {
    text-align: left;
    width: ${getRelative(40, 'mobile')};
    height: auto;

    ${mediaQuery.greaterThan('tablet')`
      width: ${getRelative(64, 'desktop')};
    `}
  }

`
interface IImage {
    isActive: boolean,
    src: string,
    alt: string,
		readonly size?: {
			height: number,
			width: number
		}
}
  

export const ImageWrapper = memo<IImage>(({ isActive, src, alt, size }) => {
  return (
    <Div isActive={isActive}>
      <img src={src} alt={alt} {...size && { width: size.width, height: size.height }}/>
    </Div>
  )
})
