import { memo, useState } from 'react'
import styled from 'styled-components'
import { getRelative, mediaQuery, getTransition } from '@/styles'
import { Lottie } from 'components/lottie'
import { ImageWrapper } from '.'
import { useMount, useDeviceType } from '@/hooks'
import { motion, AnimatePresence } from 'framer-motion'

const _LogoStyled = styled(motion.div)`
	.in_view {
    left: ${getRelative(15, 'mobile')};
    top: ${getRelative(15, 'mobile')};
    position: fixed;
    z-index: 10;

    ${mediaQuery.greaterThan('tablet')`
      left: ${getRelative(48.8, 'desktop')};
      top: ${getRelative(28, 'desktop')};
    `}
	}

  .not_in_view {
    display: none;
  }

  .lottie-container {
    left:0;
    position:fixed;
    top:0;
    width:unset;

    ${mediaQuery.greaterThan('tablet')`
      left:${getRelative(0.8, 'desktop')};
      top:${getRelative(19, 'desktop')};
			width: ${getRelative(246, 'desktop')};
    `}
  }
`
const actualVariants = {
  enter: {
    opacity: 0,
    transition: { ...getTransition(1.5), delay: 1.6}
  },
  animate: {
    opacity: 1,
    transition: { ...getTransition(1.5), delay: 1.6}
  },
  exit: {
    opacity: 0,
    transition: { ...getTransition()}
  }
}

export const Logo = memo(({  }) => {
  const [isShowing, setIsShowwing] = useState(false)
  const isMount = useMount()
  const deviceType = useDeviceType()

  const handleOnMouseEnter = () =>{
    if(deviceType !== 'mobile') setIsShowwing(!isShowing)
  }

  return (
    <>
      <AnimatePresence mode='wait'>
        <_LogoStyled animate={'animate'} exit='exit' initial='enter' variants={actualVariants}>
          <div className={'in_view'} onMouseEnter={handleOnMouseEnter} onMouseLeave={handleOnMouseEnter}>
            <ImageWrapper isActive={!isShowing} src={'images/svg/logo--monmedia--compacted.svg'} size={{ width :65.38, height: 31.88 }}  alt='Monmedia Logo' />
            {isMount && deviceType !== 'mobile' && <Lottie isActive={isShowing} name={'ON-Hover'} speed={1} />}
          </div>
        </_LogoStyled>
      </AnimatePresence>
    </>
  )
})
