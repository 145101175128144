import { memo, useMemo } from 'react'
import styled from 'styled-components'
import { motion } from 'framer-motion'
import { mediaQuery, getRelative, getP20_1} from '@/styles'

interface INavList {
  data: any,
  isActive?: boolean,
  setIsMenuActive: any
}


export const NavItemList = styled(motion.ul)<any>`
  display: grid;
  margin: ${getRelative(22, 'mobile')};
  width: 105%;

  ${mediaQuery.greaterThan('tablet')`
    width: 110%;
    margin: ${getRelative(20, 'desktop')};
  `}

  li {
    ${getP20_1()}
    border-top: .5px solid ${({theme }) => theme.colors.black};
    padding: ${getRelative(10, 'mobile')} 0 ;
    width: 100%;

    ${mediaQuery.greaterThan('tablet')`
      padding: ${getRelative(5, 'desktop')} 0 ;
    `}

    &:first-child {
      width: 90%;

      ${mediaQuery.greaterThan('tablet')`
        width: 80%;
      `}
    }

    &:last-child {
      border-bottom: .5px solid ${({theme }) => theme.colors.black};
    }
  }
`
const getListItems = (items) => items.map((item,idx)=> {
  let anchor
  if(item === 'Històries') {
    anchor = '#histories'
  } else if(item === 'Projectes') {
    anchor = '#projectesMENGEM'
  } else if(item === 'Què fem') {
    anchor = '#quefem'
  }  else if(item === 'Contacte') {
    anchor = '#contacte'
  }

  else {
    anchor = '#quisom'
  }

  return(
    <li key={idx}><a href={anchor}>{item}</a></li>
  )
})

export const NavList = memo(({ data, isActive, setIsMenuActive}:INavList) => {
  const handleOnClick = () => setIsMenuActive(prevState => !prevState)
  const ListItems = useMemo(() => getListItems(data), [data])

  return (
    <>
      {isActive ?
        <NavItemList className='nav-list' onClick={handleOnClick}>
          {ListItems}
        </NavItemList>
        :
        <></>
      }
    </>
  )
})
