import { memo, useMemo, useCallback, useRef, useEffect, useState } from 'react'
import { useInView } from 'react-intersection-observer'
import styled from 'styled-components'
import { mediaQuery, getRelative, getP22, getP20, getP18 } from '@/styles'
import { SliderFlickity } from 'components/sliders'
import ReactPlayer from 'react-player'
import { Lottie } from 'components/lottie'
import { useDeviceType } from '@/hooks'

const Overlay = styled.div`
  width: 100vw;
  height: 100vh;
  position: absolute;
  z-index: 100000;
  top: 0;
`
const HeaderStyled = styled.header`
  width: 100vw;
  height: 100vh;
  position: relative;
  z-index: 100;

  .slider--flickity {
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    video {
      position: absolute;
      object-fit: cover;
    }
  }

  .lottie-container {
    width: 100vw;
    height: auto;
    position: absolute;
    bottom: ${getRelative(100, 'mobile')};
    ${mediaQuery.greaterThan('tablet')`
       bottom: 0;
    `}
  }

`
const DivSlide = styled.div<any>`
  width: 100vw;
  height: 100vh;

`

const BgContainer = styled.div<any>`
  background: ${props => `url(${props.backGround}) no-repeat top center`};
  background-size: cover;
  width:100%;
  height:100%;
  color: white;
  .intro {
    display:none;
    /* display: grid; */
    column-gap: unset;
    grid-template-columns: calc(100% - 30px) auto;
    height:max-content;
    row-gap: ${getRelative(5, 'mobile')};
    padding: ${getRelative(35, 'mobile')} ${getRelative(10, 'mobile')};
    position: relative;
    z-index: 100;

    ${mediaQuery.greaterThan('tablet')`
      display:none;
      grid-template-columns: ${getRelative(150, 'desktop')}  ${getRelative(180, 'desktop')} ${getRelative(180, 'desktop')} auto;
      padding: ${getRelative(80, 'desktop')} ${getRelative(50, 'desktop')};
      row-gap: unset;
      column-gap: ${getRelative(10, 'desktop')};
      grid-column: unset;
      grid-row:unset;
    `}
    .title {
      grid-column: 1/span 1;
      grid-row:1;
      ${mediaQuery.greaterThan('tablet')`
        grid-column: unset;
        grid-row:unset;
    `}
    }
    .subtitle {
      grid-column:1/span 1;
      grid-row:2;
      ${mediaQuery.greaterThan('tablet')`
        grid-column: unset;
        grid-row:unset;
    `}
    }

    h2,h3,h4 {
      text-align:left;
    }
    h2 {
      ${getP22()}
      font-weight:bold;
    }
    h4 {
      ${getP18()}
      grid-column:1/span 1;
      ${mediaQuery.greaterThan('tablet')`
        grid-column: unset;
        grid-row:unset;
    `}
    }
    h3 {
      ${getP20()}
    }
  }

  .logo {
    justify-self: flex-start;
    grid-column: 1/span 1;
    grid-row:4;
    padding-top: ${getRelative(10, 'mobile')};
    ${mediaQuery.greaterThan('tablet')`
      justify-self: flex-end;
      grid-column: 4 / span 5;
      grid-row:unset;
      padding-top: 0;

      img {
        width: ${getRelative(130, 'desktop')};
        height: auto;
      }
    `}
    img {
      width: ${getRelative(130, 'mobile')};
      height: auto;
      display:block;
    }
    .wrapper {
      display: grid;
      grid-template-columns: ${getRelative(225, 'mobile')} ;
      border-left: unset;
      padding-left: unset;
      row-gap: ${getRelative(15, 'mobile')};

      ${mediaQuery.greaterThan('tablet')`
        grid-template-columns: ${getRelative(250, 'desktop')} ${getRelative(160, 'desktop')} ;
        border-left: 1px solid white;
        padding-left: ${getRelative(30, 'desktop')};
        row-gap: unset;

      `}

      .actions {
        display: flex;
        justify-content: left;
        ${mediaQuery.greaterThan('tablet')`
         display: flex;
          justify-content: right;
        `}

        button {
          margin: 0 ${getRelative(5, 'mobile')};
          ${mediaQuery.greaterThan('tablet')`
            margin: 0 ${getRelative(10, 'desktop')};
          `}
        }
        img {
          width: ${getRelative(50, 'mobile')};
          height: auto;
          ${mediaQuery.greaterThan('tablet')`
            width: ${getRelative(50, 'desktop')};
          `}
        }
      }

      .logos_wrapper {
        grid-column: unset;
        grid-row: unset;
        margin-top: unset;

        ${mediaQuery.greaterThan('tablet')`
            grid-column: 1/span 1;
            grid-row: 2;
            margin-top: ${getRelative(20, 'desktop')};
        `}
        img{
          width: ${getRelative(90, 'mobile')};
          height: auto;
          padding-bottom: ${getRelative(10, 'mobile')};

          ${mediaQuery.greaterThan('tablet')`
            width: ${getRelative(130, 'desktop')};
            height: auto;
            padding-bottom: ${getRelative(10, 'desktop')};
        `}
        }
      }
    }
  }
`

interface IHeader {
  data: any,
  onClick: any
}

const Slide = ({ data }) => {
  const deviceType = useDeviceType()
  const ref = useRef(null)
  const [playing, setIsPlaying] = useState(false)
  const { ref: inViewRef, inView } = useInView({
    threshold: 0.5
  })

  const { type, src, background_image }  = data

  const setRefs = useCallback(
    (node) => {
      ref.current = node
      inViewRef(node)
    },
    [inViewRef],
  )

  useEffect(()=>{
    if(ref?.current && (ref?.current?.classList?.contains('is-selected') && inView && deviceType !== 'mobile')) {
      setIsPlaying(true)
    } else {
      if(deviceType !== 'mobile') {
        setIsPlaying(false)
      }
    }
  },[inView, deviceType, ref])

  return (
    <DivSlide $type={type} ref={setRefs}>
      {type === 'image' ?
        <BgContainer  backGround={background_image}></BgContainer>
        :
        <ReactPlayer  width={'100%'} height={'100%'} url={src} controls={false} volume={0} muted={true} loop={deviceType === 'mobile' ? true : playing} title={''} playsinline={deviceType === 'mobile' ? true : false} playing={deviceType === 'mobile' ? true : playing} config={{ vimeo: { playerOptions: { responsive: true } }} }/>
      }
    </DivSlide>
  )
}
const getSlides = (slides) => slides.map((slide, idx) => {
  return <Slide key={idx} data={slide} ></Slide>
})


export const Header = memo<IHeader>(({ data, onClick }) => {
  const onChange = (flikifly, index) => {
    if(typeof window !== 'undefined') {
      const duration = data[index]?.type === 'image' ? 5000 : data[index]?.duration
      setTimeout(() => {
        flikifly.next()
      }, duration)
    }
  }
  const Slides = useMemo(() => getSlides(data), [data])

  return (
    <>
      <Overlay onClick={onClick}></Overlay>
      <HeaderStyled id={'header'}>
        <SliderFlickity className='slider' loop={true} pageDots={false} onChange={onChange}>
          {Slides}
        </SliderFlickity>
        <Lottie isActive={true} name={'LogoHome'} speed={1}></Lottie>
      </HeaderStyled>
    </>
  )
})
