import { memo, useRef, useState } from 'react'
import styled from 'styled-components'
import { motion, AnimatePresence } from 'framer-motion'
import { Burger } from '.'
import { mediaQuery, getRelative, getTransition} from '@/styles'

const NAV_DATA = [
  'Històries','Projectes', 'Qui Som','Què fem', 'Contacte'
]

const Nav = styled(motion.nav)<{$menuActive}>`
  align-items: flex-start;
  background-color: ${({ $menuActive, theme }) => $menuActive ?  theme.colors.grey : 'unset'};
  display: flex;
  height:auto;
  justify-content: flex-end;
  position: fixed;
	right: 0;
  top: 0;
  width:100%;
  z-index: ${({ $menuActive }) => $menuActive ? '11' : '1'};

  ${mediaQuery.greaterThan('tablet')`
    height: ${getRelative(210, 'desktop')};
		right: ${getRelative(28, 'desktop')};
    top: ${getRelative(28, 'desktop')};
    width: ${getRelative(200, 'desktop')};
  `}
`
const actualVariants = {
  enter: {
    opacity: 0,
    transition: { ...getTransition(1.5), delay: 1.6}
  },
  animate: {
    opacity: 1,
    transition: { ...getTransition(1.5), delay: 1.6}
  },
  exit: {
    opacity: 0,
    transition: { ...getTransition()}
  }
}

export const NavBar = memo(({}) => {
  const [isMenuActive, setIsMenuActive] = useState(false)
  const navbarRef= useRef(null)

  return (
    <AnimatePresence mode='wait'>
      <Nav ref={navbarRef} $menuActive={isMenuActive} animate={'animate'} exit='exit' initial='enter' variants={actualVariants}>
        <Burger menuOpen={isMenuActive} data={NAV_DATA} setIsMenuActive={setIsMenuActive}/>
      </Nav>
    </AnimatePresence>
  )
})
